var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-actions', {
    ref: "refreshCard",
    attrs: {
      "action-collapse": "",
      "title": "Daftar Admin Toko"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "2",
      "sm": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('label', {
    staticClass: "d-inline-block text-sm-left mr-50"
  }, [_vm._v("Per page")]), _c('b-form-select', {
    staticClass: "w-50",
    attrs: {
      "id": "perPageSelect",
      "size": "sm",
      "options": _vm.pageOptions
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "4",
      "sm": "8"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Sort",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "sortBySelect"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-select', {
    staticClass: "w-75",
    attrs: {
      "id": "sortBySelect",
      "options": _vm.sortOptions
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('option', {
          attrs: {
            "value": ""
          }
        }, [_vm._v("-- none --")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.sortBy,
      callback: function callback($$v) {
        _vm.sortBy = $$v;
      },
      expression: "sortBy"
    }
  }), _c('b-form-select', {
    staticClass: "w-25",
    attrs: {
      "size": "sm",
      "disabled": !_vm.sortBy
    },
    model: {
      value: _vm.sortDesc,
      callback: function callback($$v) {
        _vm.sortDesc = $$v;
      },
      expression: "sortDesc"
    }
  }, [_c('option', {
    domProps: {
      "value": false
    }
  }, [_vm._v("Asc")]), _c('option', {
    domProps: {
      "value": true
    }
  }, [_vm._v("Desc")])])], 1)], 1)], 1), _c('b-col', {
    staticClass: "my-1",
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Filter",
      "label-cols-sm": "3",
      "label-align-sm": "right",
      "label-size": "sm",
      "label-for": "filterInput"
    }
  }, [_c('b-input-group', {
    attrs: {
      "size": "sm"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "filterInput",
      "type": "search",
      "placeholder": "Tekan enter untuk cari"
    },
    on: {
      "keydown": function keydown($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        $event.preventDefault();
        return _vm.getData();
      }
    },
    model: {
      value: _vm.filter,
      callback: function callback($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  }), _c('b-input-group-append', [_c('b-button', {
    attrs: {
      "disabled": !_vm.filter
    },
    on: {
      "click": function click($event) {
        _vm.filter = '';
        _vm.getData();
      }
    }
  }, [_vm._v(" Clear ")])], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-table', {
    attrs: {
      "striped": "",
      "hover": "",
      "small": "",
      "responsive": "",
      "per-page": _vm.perPage,
      "items": _vm.items,
      "fields": _vm.fields,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(id)",
      fn: function fn(row) {
        return [_c('strong', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(foto)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [item.akun.karyawan.url_foto ? _c('img', {
          staticStyle: {
            "width": "50px"
          },
          attrs: {
            "src": item.akun.karyawan.url_foto,
            "alt": "Foto"
          }
        }) : _c('feather-icon', {
          attrs: {
            "icon": "UserIcon",
            "size": "32"
          }
        })];
      }
    }, {
      key: "cell(jk)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": _vm.jk[1][data.value]
          }
        }, [_vm._v(" " + _vm._s(_vm.jk[0][data.value]) + " ")])];
      }
    }, {
      key: "cell(status)",
      fn: function fn(data) {
        return [data.value == 0 ? _c('b-badge', {
          attrs: {
            "variant": "light-warning"
          }
        }, [_vm._v(" Pending ")]) : _vm._e(), data.value == 1 ? _c('b-badge', {
          attrs: {
            "variant": "light-success"
          }
        }, [_vm._v(" Diterima ")]) : _vm._e(), data.value == 2 ? _c('b-badge', {
          attrs: {
            "variant": "light-danger"
          }
        }, [_vm._v(" Ditolak ")]) : _vm._e()];
      }
    }, {
      key: "cell(sebagai)",
      fn: function fn(data) {
        return [_c('b-badge', {
          attrs: {
            "variant": "light-primary"
          }
        }, [_vm._v(" " + _vm._s(data.value == "marketing" ? "Marketing" : data.value == "cabang" ? "Cabang" : "-") + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(row) {
        return [_vm.allowUpdate() ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Detail',
            expression: "'Detail'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.detail(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "ListIcon"
          }
        })], 1) : _vm._e(), _vm.allowUpdate() && row.item.sebagai == null ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Konfirmasi',
            expression: "'Konfirmasi'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              return _vm.konfirmasi(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "FileIcon"
          }
        })], 1) : _vm._e(), _vm.allowUpdate() && row.item.sebagai == 'marketing' ? _c('b-button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.right",
            value: 'Edit Teritori Marketing',
            expression: "'Edit Teritori Marketing'",
            modifiers: {
              "hover": true,
              "right": true
            }
          }],
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "outline-info"
          },
          on: {
            "click": function click($event) {
              return _vm.editeritori(row.item);
            }
          }
        }, [_c('feather-icon', {
          attrs: {
            "icon": "EditIcon"
          }
        })], 1) : _vm._e()];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1), _c('b-modal', {
    attrs: {
      "no-close-on-esc": "",
      "no-close-on-backdrop": "",
      "id": "modal-tambah",
      "size": _vm.form.sebagai != null ? 'xl' : 'md',
      "ok-variant": "secondary",
      "centered": "",
      "title": "Form"
    },
    model: {
      value: _vm.showModal,
      callback: function callback($$v) {
        _vm.showModal = $$v;
      },
      expression: "showModal"
    }
  }, [_c('validation-observer', {
    ref: "formMember"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form', [_c('b-row', [_c('div', {
    staticClass: "d-flex"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserIcon",
      "size": "19"
    }
  }), _c('h6', {
    staticClass: "mb-1 ml-50"
  }, [_vm._v("Data Personal")])], 1)]), _c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "NIK",
      "label-for": "v-nik"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "nik",
      "rules": {
        required: true,
        min: 2
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref2) {
        var errors = _ref2.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-nik",
            "placeholder": "NIK",
            "type": "number"
          },
          model: {
            value: _vm.form.nik,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "nik", $$v);
            },
            expression: "form.nik"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Nama Lengkap",
      "label-for": "v-nama-lengkap"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "nama",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-nama-lengkap",
            "placeholder": "Nama Lengkap"
          },
          model: {
            value: _vm.form.nama_lengkap,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "nama_lengkap", $$v);
            },
            expression: "form.nama_lengkap"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Foto",
      "label-for": "v-file_foto"
    }
  }, [_c('input', {
    ref: "file_foto",
    attrs: {
      "type": "file"
    },
    on: {
      "change": _vm.onSelectedImage
    }
  }), _vm.preview_image ? _c('div', {
    staticClass: "my-2"
  }, [_c('img', {
    staticStyle: {
      "width": "100px"
    },
    attrs: {
      "src": _vm.preview_image,
      "alt": ""
    }
  })]) : _vm._e()])], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jenis Kelamin",
      "label-for": "v-jk"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "jk",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.optjk
          },
          model: {
            value: _vm.form.jk,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "jk", $$v);
            },
            expression: "form.jk"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Agama",
      "label-for": "v-agama"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "agama",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.optagama
          },
          model: {
            value: _vm.form.agama,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "agama", $$v);
            },
            expression: "form.agama"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tempat Lahir",
      "label-for": "v-tempat_lahir"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tempat_lahir",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-tempat_lahir",
            "placeholder": "Tempat Lahir"
          },
          model: {
            value: _vm.form.tempat_lahir,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "tempat_lahir", $$v);
            },
            expression: "form.tempat_lahir"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "sm": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tanggal Lahir",
      "label-for": "v-tanggal_lahir"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "tanggal_lahir",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-input', {
          attrs: {
            "type": "date",
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.form.tanggal_lahir,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "tanggal_lahir", $$v);
            },
            expression: "form.tanggal_lahir"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Status Perkawinan",
      "label-for": "v-status_perkawinan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "status_perkawinan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.optstatus_perkawinan
          },
          model: {
            value: _vm.form.status_perkawinan,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "status_perkawinan", $$v);
            },
            expression: "form.status_perkawinan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Nomor HP",
      "label-for": "v-nomor-hp"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "no_hp",
      "rules": {
        min: 10,
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-nomor-hp",
            "placeholder": "Nomor HP",
            "type": "number"
          },
          model: {
            value: _vm.form.no_hp,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "no_hp", $$v);
            },
            expression: "form.no_hp"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-for": "v-email"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "email",
      "rules": {
        required: true,
        email: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var errors = _ref10.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-email",
            "type": "email",
            "placeholder": "Email"
          },
          model: {
            value: _vm.form.email,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "email", $$v);
            },
            expression: "form.email"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Alamat",
      "label-for": "v-alamat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "nip",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('b-form-textarea', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-alamat",
            "placeholder": "Alamat Lengkap"
          },
          model: {
            value: _vm.form.alamat,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "alamat", $$v);
            },
            expression: "form.alamat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form', [_c('b-row', [_c('div', {
    staticClass: "d-flex"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "BookOpenIcon",
      "size": "19"
    }
  }), _c('h6', {
    staticClass: "mb-1 ml-50"
  }, [_vm._v("Data Pendidikan")])], 1)]), _c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Pendidikan Terakhir",
      "label-for": "v-pendidikan_terakhir"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "pendidikan_terakhir",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var errors = _ref12.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-pendidikan_terakhir",
            "placeholder": "Pendidikan Terakhir"
          },
          model: {
            value: _vm.form.pendidikan_terakhir,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "pendidikan_terakhir", $$v);
            },
            expression: "form.pendidikan_terakhir"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Kampus / Sekolah",
      "label-for": "v-kampus_sekolah"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "kampus_sekolah",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var errors = _ref13.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-kampus_sekolah",
            "placeholder": "Kampus / Sekolah"
          },
          model: {
            value: _vm.form.kampus_sekolah,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "kampus_sekolah", $$v);
            },
            expression: "form.kampus_sekolah"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Kualifikasi Pendidikan",
      "label-for": "v-kualifikasi_pendidikan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "kualifikasi_pendidikan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var errors = _ref14.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-kualifikasi_pendidikan",
            "placeholder": "Kualifikasi Pendidikan"
          },
          model: {
            value: _vm.form.kualifikasi_pendidikan,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "kualifikasi_pendidikan", $$v);
            },
            expression: "form.kualifikasi_pendidikan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form', [_c('b-row', [_c('div', {
    staticClass: "d-flex"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "BriefcaseIcon",
      "size": "19"
    }
  }), _c('h6', {
    staticClass: "mb-1 ml-50"
  }, [_vm._v("Data Kepegawaian")])], 1)]), _c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "NIP",
      "label-for": "v-nip"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "nip",
      "rules": {
        required: true,
        min: 2
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var errors = _ref15.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-nip",
            "placeholder": "NIP",
            "type": "number"
          },
          model: {
            value: _vm.form.nip,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "nip", $$v);
            },
            expression: "form.nip"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Jabatan",
      "label-for": "v-jabatan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "jabatan",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref16) {
        var errors = _ref16.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.optjabatan_id,
            "label": "Jabatan"
          },
          model: {
            value: _vm.form.jabatan_id,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "jabatan_id", $$v);
            },
            expression: "form.jabatan_id"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Status Member",
      "label-for": "v-status_Member"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "status_Member",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref17) {
        var errors = _ref17.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.optstatus_Member
          },
          model: {
            value: _vm.form.status_Member,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "status_Member", $$v);
            },
            expression: "form.status_Member"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Status SP",
      "label-for": "v-status_sp"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "status_sp",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref18) {
        var errors = _ref18.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.optstatus_sp
          },
          model: {
            value: _vm.form.status_sp,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "status_sp", $$v);
            },
            expression: "form.status_sp"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Masa Kerja",
      "label-for": "v-masa_kerja"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "masa_kerja",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref19) {
        var errors = _ref19.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-masa_kerja",
            "placeholder": "Masa Kerja"
          },
          model: {
            value: _vm.form.masa_kerja,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "masa_kerja", $$v);
            },
            expression: "form.masa_kerja"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Grade Member",
      "label-for": "v-grade_Member"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "grade_Member",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref20) {
        var errors = _ref20.errors;
        return [_c('b-form-input', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "id": "v-grade_Member",
            "placeholder": "Grade Member"
          },
          model: {
            value: _vm.form.grade_Member,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "grade_Member", $$v);
            },
            expression: "form.grade_Member"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1), _c('b-col', [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submit($event);
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.label || "Submit") + " ")])], 1)], 1)], 1)], 1)], 1)], 1), _c('b-modal', {
    attrs: {
      "no-close-on-esc": "",
      "no-close-on-backdrop": "",
      "id": "modal-detail",
      "size": "xl",
      "ok-variant": "secondary",
      "centered": "",
      "title": "Detail dan Konfirmasi Member"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              _vm.modaldetail = false;
              _vm.getData();
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.modaldetail,
      callback: function callback($$v) {
        _vm.modaldetail = $$v;
      },
      expression: "modaldetail"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex justify-content-between flex-column",
    attrs: {
      "xl": _vm.form.sebagai != null ? 7 : 12,
      "lg": _vm.form.sebagai != null ? 7 : 12,
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "border-variant": "primary"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "12",
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-start"
  }, [_c('b-avatar', {
    attrs: {
      "src": _vm.form.akun == null ? '-' : _vm.form.akun.karyawan ? _vm.form.akun.karyawan.url_foto : '-',
      "text": _vm.form.nama_lengkap,
      "size": "104px",
      "rounded": ""
    }
  }), _c('div', {
    staticClass: "d-flex flex-column ml-1"
  }, [_c('div', {
    staticClass: "mb-1"
  }, [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.form.nama_lengkap) + " ")]), _c('span', {
    staticClass: "card-text"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MailIcon",
      "size": "14"
    }
  }), _vm._v(" E-mail : " + _vm._s(_vm.form.email))], 1), _c('br'), _c('span', {
    staticClass: "card-text"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "KeyIcon",
      "size": "14"
    }
  }), _vm._v(" Seller ID : " + _vm._s(_vm.form.sellerid ? _vm.form.sellerid : "-"))], 1), _vm._v(" "), _c('br'), _c('span', {
    staticClass: "card-text"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "MapPinIcon",
      "size": "14"
    }
  }), _vm._v(" Alamat : " + _vm._s(_vm.form.alamat ? _vm.form.alamat : "-") + ", " + _vm._s(_vm.form.kelurahan ? _vm.form.kelurahan.name : "-") + ", " + _vm._s(_vm.form.kecamatan ? _vm.form.kecamatan.name : "-") + ", " + _vm._s(_vm.form.kab_kota ? _vm.form.kab_kota.name : "-") + ", " + _vm._s(_vm.form.provinsi ? _vm.form.provinsi.name : "-"))], 1)]), _c('div', {
    staticClass: "d-flex flex-wrap"
  })])], 1)])], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "xl": "12",
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mt-1"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mr-2"
  }, [_c('b-avatar', {
    attrs: {
      "variant": "light-primary",
      "rounded": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "ListIcon",
      "size": "18"
    }
  })], 1), _c('div', {
    staticClass: "ml-1"
  }, [_c('small', [_vm._v("Seller Status")]), _c('h5', {
    staticClass: "mb-0"
  }, [_vm.form.sebagai == null ? [_c('b-button', {
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.konfirmasi2(_vm.form);
      }
    }
  }, [_vm._v("Belum Konfirmasi")])] : [_vm._v(_vm._s(_vm.form.sebagai == "marketing" ? "Marketing" : "Cabang"))]], 2)])], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-avatar', {
    attrs: {
      "variant": "light-info",
      "rounded": ""
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserIcon",
      "size": "18"
    }
  })], 1), _c('div', {
    staticClass: "ml-1"
  }, [_c('small', [_vm._v("Jenis Kelamin")]), _c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.form.jk == 1 ? "Laki-laki" : "Perempuan") + " ")])])], 1)]), _c('table', {
    staticClass: "mt-2 mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "CreditCardIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("KTP")])], 1), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" " + _vm._s(_vm.form.card ? _vm.form.card : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "InstagramIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Instagram")])], 1), _c('td', {
    staticClass: "pb-50 text-capitalize"
  }, [_vm._v(" " + _vm._s(_vm.form.ig ? _vm.form.ig : "-") + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "SmartphoneIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Whatsapp")])], 1), _c('td', {
    staticClass: "pb-50 text-capitalize"
  }, [_vm._v(" " + _vm._s(_vm.form.wa ? _vm.form.wa : "-") + " ")])])])])], 1)], 1)], 1), _vm.form.sebagai != null ? _c('b-col', {
    attrs: {
      "xl": "5",
      "lg": "5",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "border-variant": "primary"
    }
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v("Lokasi Cabang")]), _vm.form.sebagai == 'marketing' ? _c('b-table-simple', {
    attrs: {
      "hover": "",
      "small": "",
      "caption-top": "",
      "responsive": ""
    }
  }, [_c('b-thead', {
    attrs: {
      "head-variant": "dark"
    }
  }, [_c('b-tr', [_c('b-th', [_vm._v("No")]), _c('b-th', [_vm._v("Teritori")]), _c('b-th', [_vm._v("Kota")]), _c('b-th', [_vm._v("Nama Cabang")])], 1)], 1), _c('b-tbody', _vm._l(_vm.form.wilayah, function (item, index) {
    return _c('b-tr', {
      key: index
    }, [_c('b-td', [_vm._v(_vm._s(index + 1))]), _c('b-td', [_vm._v(_vm._s(item.nama_teritori))]), _c('b-td', [_vm._v(_vm._s(item.nama_kota))]), _c('b-td', [_vm._v(_vm._s(item.nama_cabang))])], 1);
  }), 1), _c('b-tfoot', [_c('b-tr', [_c('b-td', {
    staticClass: "text-right",
    attrs: {
      "colspan": "7",
      "variant": "secondary"
    }
  }, [_vm._v(" Total Rows: "), _c('b', [_vm._v(_vm._s(_vm.form.wilayah.length))])])], 1)], 1)], 1) : _vm._e(), _vm.form.sebagai == 'cabang' ? _c('b-table-simple', {
    attrs: {
      "hover": "",
      "small": "",
      "caption-top": "",
      "responsive": ""
    }
  }, [_c('b-thead', {
    attrs: {
      "head-variant": "dark"
    }
  }, [_c('b-tr', [_c('b-th', [_vm._v("No")]), _c('b-th', [_vm._v("Teritori")]), _c('b-th', [_vm._v("Kota")]), _c('b-th', [_vm._v("Nama Cabang")])], 1)], 1), _c('b-tbody', _vm._l(_vm.form.wilayah, function (item, index) {
    return _c('b-tr', {
      key: index
    }, [_c('b-td', [_vm._v(_vm._s(index + 1))]), _c('b-td', [_vm._v(_vm._s(item.cabang.nama_teritori))]), _c('b-td', [_vm._v(_vm._s(item.cabang.nama_kota))]), _c('b-td', [_vm._v(_vm._s(item.cabang.nama_cabang))])], 1);
  }), 1), _c('b-tfoot', [_c('b-tr', [_c('b-td', {
    staticClass: "text-right",
    attrs: {
      "colspan": "7",
      "variant": "secondary"
    }
  }, [_vm._v(" Total Rows: "), _c('b', [_vm._v(_vm._s(_vm.form.wilayah.length))])])], 1)], 1)], 1) : _vm._e()], 1)], 1) : _vm._e()], 1)], 1), _c('b-modal', {
    attrs: {
      "no-close-on-esc": "",
      "no-close-on-backdrop": "",
      "id": "modal-konfirmasi",
      "size": "md",
      "ok-variant": "secondary",
      "centered": "",
      "title": "Konfirmasi Member"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              _vm.modalkonfirmasi = false;
              _vm.getData();
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.modalkonfirmasi,
      callback: function callback($$v) {
        _vm.modalkonfirmasi = $$v;
      },
      expression: "modalkonfirmasi"
    }
  }, [_c('b-row', [_vm.form.sebagai == null ? _c('b-col', {
    attrs: {
      "xl": "12",
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "border-variant": "primary"
    }
  }, [_c('validation-observer', {
    ref: "formKonfirmasi"
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "12",
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Status Konfirmasi",
      "label-for": "v-status"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "status",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref21) {
        var errors = _ref21.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.optstatus
          },
          model: {
            value: _vm.form.status,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "status", $$v);
            },
            expression: "form.status"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2551942797)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xl": "12",
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Seller Status",
      "label-for": "v-sebagai"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "sebagai",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref22) {
        var errors = _ref22.errors;
        return [_c('b-form-select', {
          attrs: {
            "state": errors.length > 0 ? false : null,
            "options": _vm.optsebagai
          },
          model: {
            value: _vm.form.seller_status,
            callback: function callback($$v) {
              _vm.$set(_vm.form, "seller_status", $$v);
            },
            expression: "form.seller_status"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3971752541)
  })], 1)], 1), _vm.form.status != 2 ? _c('b-col', {
    attrs: {
      "xl": "12",
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Teritori",
      "label-for": "v-teritori"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "teritori",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref23) {
        var errors = _ref23.errors;
        return [_vm.form.seller_status == 1 || _vm.form.seller_status == null || _vm.form.seller_status == '' ? _c('v-select', {
          attrs: {
            "reduce": function reduce(option) {
              return option.value;
            },
            "multiple": "",
            "label": "text",
            "options": _vm.optwilter,
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.pilihteritori,
            callback: function callback($$v) {
              _vm.pilihteritori = $$v;
            },
            expression: "pilihteritori"
          }
        }) : _vm._e(), _vm.form.seller_status == 2 ? _c('v-select', {
          attrs: {
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "text",
            "options": _vm.optwilter,
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.pilihteritori,
            callback: function callback($$v) {
              _vm.pilihteritori = $$v;
            },
            expression: "pilihteritori"
          }
        }) : _vm._e(), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2897580380)
  })], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('b-button', {
    attrs: {
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submitkonfirmasi($event);
      }
    }
  }, [_vm._v(" Konfirmasi ")])], 1)], 1) : _vm._e()], 1)], 1), _c('b-modal', {
    attrs: {
      "no-close-on-esc": "",
      "no-close-on-backdrop": "",
      "id": "modal-editter",
      "size": "md",
      "ok-variant": "secondary",
      "centered": "",
      "title": "Edit Teritori"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('section', {
          staticClass: "d-flex justify-content-end align-items-center"
        }, [_c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "danger"
          },
          on: {
            "click": function click($event) {
              _vm.modaleditter = false;
              _vm.getData();
            }
          }
        }, [_vm._v(" Keluar ")])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.modaleditter,
      callback: function callback($$v) {
        _vm.modaleditter = $$v;
      },
      expression: "modaleditter"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "xl": "12",
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "border-variant": "primary"
    }
  }, [_c('validation-observer', {
    ref: "formEditKonfirmasi"
  }, [_c('b-form', [_c('b-row', [_vm.form.status != 2 ? _c('b-col', {
    attrs: {
      "xl": "12",
      "lg": "12",
      "md": "12",
      "sm": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Teritori",
      "label-for": "v-teritori"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "teritori",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref24) {
        var errors = _ref24.errors;
        return [_vm.form.sebagai == 'cabang' || _vm.form.sebagai == null || _vm.form.sebagai == '' ? _c('v-select', {
          attrs: {
            "reduce": function reduce(option) {
              return option.value;
            },
            "multiple": "",
            "label": "text",
            "options": _vm.optwilter,
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.pilihteritori,
            callback: function callback($$v) {
              _vm.pilihteritori = $$v;
            },
            expression: "pilihteritori"
          }
        }) : _vm._e(), _vm.form.sebagai == 'marketing' ? _c('v-select', {
          attrs: {
            "reduce": function reduce(option) {
              return option.value;
            },
            "label": "text",
            "options": _vm.optwilter,
            "state": errors.length > 0 ? false : null
          },
          model: {
            value: _vm.pilihteritori,
            callback: function callback($$v) {
              _vm.pilihteritori = $$v;
            },
            expression: "pilihteritori"
          }
        }) : _vm._e(), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3070930995)
  })], 1)], 1) : _vm._e()], 1)], 1)], 1), _c('b-button', {
    attrs: {
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.submiteditter($event);
      }
    }
  }, [_vm._v(" Edit Teritori ")]), _c('b-button', {
    attrs: {
      "variant": "danger",
      "block": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.hapuseditter($event);
      }
    }
  }, [_vm._v(" Lepas/Kosongkan Teritori ")])], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }